
<template>
    <div class="login login-v2" data-pageload-addclass="animated fadeIn">
      <!-- begin brand -->
      <div class="login-header">
        <div class="brand">
          <span class="logo"></span> <b>P2C</b>
          <small>responsive bootstrap 4 admin template</small>
        </div>
      </div>
      <div class="login-content">
        <form class="margin-bottom-0">
          <form-group-thux :validator="$v.form.new_password1" :label="'New password' | translate" class="mb-2 mt-2">
                <template slot-scope="{ attrs, listeners }">
                  <b-form-input
                    type="password"
                    v-bind="attrs"
                    v-on="listeners"
                    v-model="form.new_password1"
                  />
                </template>
              </form-group-thux>
              <form-group-thux :validator="$v.form.new_password2" :label="'Confirm new password' | translate" class="mb-0 mt-2">
                <template slot-scope="{ attrs, listeners }">
                  <b-form-input
                    type="password"
                    v-bind="attrs"
                    v-on="listeners"
                    v-model="form.new_password2"
                  />
                </template>
              </form-group-thux>
          <div class="login-buttons mt-5 d-flex justify-content-between align-items-center">
            <button
              class="btn btn-success btn-lg"
              :disabled="$v.form.$invalid"
              @click.prevent="onSubmit()">
              {{ 'Change password' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { ROUTES as AUTH_ROUTES } from './router'
import { TYPES as AUTH_TYPES } from './store'

export default {
  name: 'reset-password-confirm',
  data () {
    return {
      form: {},
      topModalSize: 'sm',
      uid: this.$route.params.uid,
      token: this.$route.params.token
    }
  },
  methods: {
    ...mapActions({
      ...AUTH_TYPES.AUTH
    }),
    onSubmit () {
      if (!this.$v.$invalid) {
        const data = {
          token: this.token,
          uid: this.uid
        }
        this.resetPasswordConfirm({ ...data, ...this.form }).then(
          response => {
            this.$store.dispatch('notifySuccess', { title: this.$t('Password Reset Success'), text: this.$t('Password has been reset with the new password.') })
            setTimeout(() => {
              this.goToLogin()
            }, 1000)
          },
          error => {
            this.$store.dispatch('notifyError', { error: error.response.data })
          }
        )
      }
    },
    goToLogin () {
      this.$router.replace({ name: AUTH_ROUTES.LOGIN })
    }
  },
  created () {
    this.form = {}
  },
  mounted () {

  },
  validations: {
    form: {
      new_password1: {
        required
      },
      new_password2: {
        required,
        sameAsPassword: sameAs('new_password1')
      }
    }
  }
}
</script>
